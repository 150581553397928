<template>
  <div
    v-if="selectedGameDay && selectedGameDay.games && selectedGameDay.games.length > 0"
    class="flex flex-column card-container"
  >
    <div class="flex align-items-center justify-content-center">
      <Dropdown
        v-model="selectedGameDay"
        :options="selectableCupGameDays"
        :editable="false"
        optionLabel="representation"
        style="width: 500px; margin: 10px"
      />
    </div>
    <div v-if="isCupAdmin" class="flex align-items-center justify-content-center">
      <InputText
        v-model="searchTextData"
        placeholder="Suchwert, z.B. Teil des Namens einer Mannschaft"
        @input="executeSearch"
        style="width: 500px; margin: 10px"
      />
    </div>
    <div class="flex align-items-center justify-content-center">
      <DataTable
        :rowClass="rowClass"
        class="p-datatable-sm"
        :value="gamesForCurrentTeam"
        responsiveLayout="stack"
        breakpoint="550px"
      >
        <Column field="date" header="Datum">
          <template #body="slotProps">
            {{ formatDate(slotProps.data) }}
          </template>
        </Column>
        <Column header="Heim">
          <template #body="slotProps">
            {{ slotProps.data.teamHome.representation }} ({{
              slotProps.data.teamHome.leagueAbbreviation
            }})
            <Button
              v-if="showPlayersDialogButton(slotProps.data, true)"
              style="margin: 2px 10px; vertical-align: middle"
              icon="pi pi-users"
              v-tooltip.top="'Spieler-Übersicht'"
              class="p-button-rounded p-button-info mb2"
              @click="showPlayersDialog(slotProps.data, true)"
            />
          </template>
        </Column>
        <Column header="Ergebnis" style="text-align: center">
          <template #body="slotProps">
            {{ slotProps.data.pointsHome }} : {{ slotProps.data.pointsGuest }}
          </template>
        </Column>
        <Column header="Gast">
          <template #body="slotProps">
            {{ slotProps.data.teamGuest.representation }} ({{
              slotProps.data.teamGuest.leagueAbbreviation
            }})
            <Button
              v-if="showPlayersDialogButton(slotProps.data, false)"
              style="margin: 2px 10px; vertical-align: middle"
              icon="pi pi-users"
              v-tooltip.top="'Spieler-Übersicht'"
              class="p-button-rounded p-button-info mb2"
              @click="showPlayersDialog(slotProps.data, false)"
            />
          </template>
        </Column>
        <Column v-if="isCupAdmin" header="Spielstatus">
          <template #body="slotProps">
            {{ translateGameStatus(slotProps.data) }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <div v-if="adminOrOwnGameHomeTodayOrPast(slotProps.data)">
              <Button
                style="margin: 2px 2px"
                icon="pi pi-pencil"
                v-tooltip.top="'Editieren'"
                class="p-button-rounded p-button-warning mb2"
                @click="editGame(slotProps.data)"
              />
              <Button
                v-if="gamePlayed(slotProps.data)"
                style="margin: 2px 2px"
                icon="pi pi-eye"
                v-tooltip.top="'Details'"
                class="p-button-rounded p-button-success mb2"
                @click="showGame(slotProps.data)"
              />
            </div>
            <div v-else-if="ownGameFuture(slotProps.data)">
              <Button
                style="margin: 2px 2px"
                icon="pi pi-calendar-times"
                v-tooltip.top="'Verlegen'"
                class="p-button-rounded p-button-warning mb2"
                @click="this.handleGameRequest(slotProps.data, 'changeCupDateRequest')"
              />
              <Button
                style="margin: 2px 2px"
                icon="pi pi-code"
                v-tooltip.top="'Heimrecht tauschen'"
                class="p-button-rounded p-button-warning mb2"
                @click="
                  this.handleGameRequest(slotProps.data, 'switchCupHomeGuestRequest')
                "
              />
            </div>

            <div v-else>
              <Button
                v-if="gamePlayed(slotProps.data)"
                style="margin: 2px 2px"
                icon="pi pi-eye"
                v-tooltip.top="'Details'"
                class="p-button-rounded p-button-success mb2"
                @click="showGame(slotProps.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      id="showPlayersDialog"
      modal
      v-model:visible="displayPlayersDialog"
      style="min-width: 300px; max-width: 600px"
      :closable="true"
    >
      <template #header>
        <div
          class="align-items-center justify-content-center"
          style="font-size: 16px; text-align: center; width: 100%; margin: 5px 15px"
        >
          Gemeldete Spieler der Mannschaft <br />
          {{ teamToShowPlayer }}
        </div>
      </template>
      <div class="flex flex-column align-items-center justify-content-center">
        <DataTable
          class="p-datatable-sm"
          :value="playersToShow"
          style="margin-top: 5px; width: 100%"
        >
          <Column field="name" header="Name"></Column>
        </DataTable>
      </div>
    </Dialog>
  </div>
  <div v-else>
    <ProgressSpinner style="margin-top: 20px" />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { useWizardService } from "../../services/WizardService/UseWizardService";
export default {
  name: "TeamAdminCupView",
  components: {
    Dropdown,
    DataTable,
    Column,
    Button,
    ProgressSpinner,
    Dialog,
    InputText,
  },
  created() {
    this.loadCupLeague();
  },
  updated() {
    this.loadCupLeague();
  },
  props: {
    currentTeamId: Number,
  },
  data() {
    return {
      wizardService: useWizardService(),
      cupGameDays: [],
      selectedGameDay: null,
      playersToShow: [],
      displayPlayersDialog: false,
      searchTextData: "",
    };
  },
  computed: {
    selectableCupGameDays() {
      if (!this.cupGameDays || this.cupGameDays.length <= 0) {
        return [];
      }
      return this.cupGameDays.filter((gd) => gd.games && gd.games.length > 0);
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isCupAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        user.roles &&
        !user.roles.includes("admin") &&
        user.roles.includes("cupAdmin")
      );
    },
    gamesForCurrentTeam() {
      if (this.selectedGameDay) {
        if (this.isCupAdmin) {
          if (this.searchTextData && this.searchTextData.length > 0) {
            return this.selectedGameDay.games.filter(
              (game) =>
                (game.teamHome.representation &&
                  game.teamHome.representation
                    .toLowerCase()
                    .includes(this.searchTextData.toLowerCase())) ||
                (game.teamGuest.representation &&
                  game.teamGuest.representation
                    .toLowerCase()
                    .includes(this.searchTextData.toLowerCase()))
            );
          }
          return this.selectedGameDay.games;
        }
        var games = [];
        var ownGames = this.selectedGameDay.games.filter((g) => this.ownGame(g));
        games.push.apply(games, ownGames);
        games.push.apply(
          games,
          this.selectedGameDay.games.filter((g) => !this.ownGame(g))
        );
        return games;
      }
      return [];
    },
  },
  methods: {
    loadCupLeague() {
      if (!this.cupGameDays || this.cupGameDays.length <= 0) {
        ServerConnection.get("data/cup/current/gamedays")
          .then((response) => response.data)
          .then((data) => {
            this.cupGameDays = data;
            var selectableGameDays = this.cupGameDays.filter(
              (gd) => gd.games && gd.games.length > 0
            );
            if (selectableGameDays.length > 0) {
              this.selectedGameDay = selectableGameDays[selectableGameDays.length - 1];
            }
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    executeSearch() {},
    editGame(data) {
      this.$router.push("/game/edit/" + data.id);
    },
    ownGameFuture(data) {
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      var gameDate = new Date(data.date.replace(pattern, "$3-$2-$1"));
      return (
        this.ownGame(data) && new Date() < gameDate && this.statusAllowsEditing(data)
      );
    },
    ownGame(data) {
      if (data && data.teamHome && data.teamGuest) {
        return (
          data.teamHome.id == this.currentTeamId ||
          data.teamGuest.id == this.currentTeamId
        );
      }
      return false;
    },
    adminOrOwnGameHomeTodayOrPast(data) {
      if (this.isCupAdmin) {
        return true;
      }
      var gameDate = new Date(data.date);
      return (
        data.teamHome.id === this.currentTeamId &&
        new Date() >= gameDate &&
        this.statusAllowsEditing(data)
      );
    },
    handleGameRequest(game, wizardId) {
      this.wizardService.navigateToWizard(
        this.$router,
        wizardId,
        {
          baseRecord: game,
          teamId: game.teamHome.id,
        },
        this.$route.fullPath
      );
    },
    statusAllowsEditing(data) {
      if (data && data.gameStatus) {
        switch (data.gameStatus) {
          case "ToPlay":
          case "BeingPlayed":
          case "WaitingForResults":
          case "WaitingForImage":
          case "WaitingForHomeApproval":
          case "PostPoned":
            return true;
          default:
            return false;
        }
      }
      return false;
    },
    gamePlayed(game) {
      if (this.isCupAdmin) {
        return true;
      }
      return (
        game.pointsHome != null &&
        game.pointsGuest != null &&
        (game.pointsHome > 0 || game.pointsGuest > 0)
      );
    },
    showGame(data) {
      this.$router.push("/game/view/" + data.id);
    },
    showPlayersDialogButton(game, isHome) {
      if (this.isCupAdmin) {
        return true;
      }
      if (this.gamePlayed(game)) {
        return false;
      }
      if (this.currentTeamId) {
        if (isHome) {
          return this.currentTeamId == game.teamGuest.id;
        } else {
          return this.currentTeamId == game.teamHome.id;
        }
      }
      return false;
    },
    showPlayersDialog(game, isHome) {
      var url;
      if (isHome) {
        url = "data/game/" + game.id + "/players/home";
        this.teamToShowPlayer = game.teamHome.representation;
      } else {
        url = "data/game/" + game.id + "/players/guest";
        this.teamToShowPlayer = game.teamGuest.representation;
      }
      ServerConnection.get(url)
        .then((response) => response.data)
        .then((data) => {
          this.playersToShow = data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
      this.displayPlayersDialog = true;
    },
    rowClass(data) {
      return this.ownGame(data) ? "owngame" : null;
    },
    formatDate(data) {
      if (data.date && data.date.length > 0) {
        var date = new Date(data.date);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var hour = date.getHours();
        var minutes = date.getMinutes();
        return (
          (day < 10 ? "0" + day : day) +
          "." +
          (month < 10 ? "0" + month : month) +
          "." +
          date.getFullYear() +
          " " +
          (hour < 10 ? "0" + hour : hour) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          " Uhr"
        );
      }
      return "";
    },
    translateGameStatus(game) {
      if (game && game.gameStatus) {
        switch (game.gameStatus) {
          case "ToPlay":
            return "Zu spielen";
          case "BeingPlayed":
            return "Wird gerade gespielt";
          case "WaitingForResults":
            return "Noch kein Endergebnis";
          case "WaitingForImage":
            return "Noch kein Bild des Endergebnisses";
          case "WaitingForHomeApproval":
            return "In Bestätigung durch Heim";
          case "InDiscussion":
            return "In Abstimmung";
          case "WaitingForGuestApproval":
            return "In Bestätigung durch Gast";
          case "WaitingForGameManagerApproval":
            return "In Kontrolle durch Spielleiter";
          case "Played":
            return "Gespielt";
          case "PostPoned":
            return "Verlegt";
          case "Unrated":
            return "Keine Wertung";
          case "ChangeRequested":
            return "Änderung beim Gegner angefragt";
        }
      }
      return "";
    },
    resetData() {
      this.cupGameDays = [];
    },
  },
};
</script>

<style lang="css">
.owngame {
  background-color: #b9c4ff !important;
}
</style>
