<template>
  <Fieldset
    legend="Spiele"
    :toggleable="true"
    :collapsed="gameViewCollapsed"
    @toggle="toogleGameView()"
    style="min-width: 300px"
  >
    <div v-if="totalGameDaysCount > 0">
      <Paginator
        v-model:first="currentIndex"
        :rows="1"
        :pageLinkSize="totalGameDaysCount"
        template="PageLinks"
        :totalRecords="totalGameDaysCount"
        @page="onPage($event)"
      >
      </Paginator>
      <h4>{{ currentGameDay.spieltag }}. Spieltag</h4>
      <div
        class="align-items-center justify-content-center gamesView"
        style="display: flex"
      >
        <DataTable
          class="p-datatable-sm"
          :value="currentGameDay.alleSpiele"
          :rowClass="rowClass"
          responsiveLayout="stack"
          breakpoint="550px"
        >
          <Column header="Datum">
            <template #body="slotProps">
              {{ slotProps.data.datum }}
              {{
                slotProps.data.uhrzeit && slotProps.data.uhrzeit != null
                  ? slotProps.data.uhrzeit
                  : ""
              }}
            </template>
          </Column>
          <Column field="heimmannschaft" header="Heim" />
          <Column field="ergebnis" header="Ergebnis" style="text-align: center" />
          <Column field="gastmannschaft" header="Gast" />
          <Column header="Spielstatus">
            <template #body="slotProps">
              {{ translateGameStatus(slotProps.data) }}
            </template>
          </Column>
          <Column header="">
            <template #body="slotProps">
              <Button
                style="margin: 2px 2px"
                icon="pi pi-eye"
                v-tooltip.top="'Details'"
                class="p-button-rounded p-button-success mb2"
                @click="showGame(slotProps.data)"
              />
              <Button
                style="margin: 2px 2px"
                icon="pi pi-pencil"
                v-tooltip.top="'Editieren'"
                class="p-button-rounded p-button-warning mb2"
                @click="editGame(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else>
      <ProgressSpinner style="margin-top: 20px" />
    </div>
  </Fieldset>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Fieldset from "primevue/fieldset";
export default {
  name: "AdminGameDayView",
  components: {
    Paginator,
    DataTable,
    Column,
    Button,
    ProgressSpinner,
    Fieldset,
  },
  data() {
    return {
      gameDays: [],
      currentGameDay: {},
      oldMannschaftId: undefined,
      oldLeagueId: undefined,
      currentIndex: -1,
      gameViewCollapsed: this.collapsed,
    };
  },
  computed: {
    totalGameDaysCount() {
      return this.gameDays ? this.gameDays.length : 0;
    },
  },
  props: {
    mannschaft: { require: false },
    league: { require: false },
    gameDayNumber: { require: false, default: -1 },
    collapsed: { require: false, default: true },
  },
  mounted() {
    this.loadGameDays();
  },
  updated() {
    this.loadGameDays();
  },
  methods: {
    toogleGameView() {
      this.gameViewCollapsed = !this.gameViewCollapsed;
    },
    loadGameDays() {
      var response = null;
      if (!this.gameViewCollapsed) {
        if (
          this.mannschaft &&
          this.mannschaft.id &&
          (!this.oldMannschaftId || this.oldMannschaftId != this.mannschaft.id)
        ) {
          this.oldMannschaftId = this.mannschaft.id;

          response = ServerConnection.get(
            "data/saison/current/mannschaft/" + this.mannschaft.id + "/games"
          );
        } else if (
          this.league &&
          this.league.id &&
          (this.oldLeagueId || this.oldLeagueId != this.league.id)
        ) {
          this.oldLeagueId = this.league.id;
          response = ServerConnection.get(
            "data/saison/current/league/" + this.league.id + "/games"
          );
        }
        if (response != null) {
          response
            .then((response) => response.data)
            .then((data) => {
              this.gameDays = data;
              var storedCurrentIndex = localStorage.getItem("currentGameDayIndex");
              if (storedCurrentIndex) {
                localStorage.removeItem("currentGameDayIndex");
                this.currentIndex = parseInt(storedCurrentIndex);
                this.currentGameDay = this.gameDays[this.currentIndex];
              } else if (this.gameDayNumber && this.gameDayNumber > 0) {
                this.currentIndex = this.gameDayNumber - 1;
                this.currentGameDay = this.gameDays[this.currentIndex];
              } else {
                this.currentGameDay = this.gameDays.find(
                  (gameDay) => gameDay.istNaechterSpieltag
                );
                if (this.currentIndex < 0) {
                  this.currentIndex =
                    this.gameDays && this.currentGameDay
                      ? this.gameDays.indexOf(this.currentGameDay)
                      : 0;
                }
              }
            })
            .catch((error) => {
              FailureHandler.handleServerFailure(this.$router, error);
            });
        }
      }
    },
    editGame(data) {
      localStorage.setItem("currentGameDayIndex", this.currentIndex);
      this.$router.push("/game/edit/" + data.id);
    },
    showGame(data) {
      localStorage.setItem("currentGameDayIndex", this.currentIndex);
      this.$router.push("/game/view/" + data.id);
    },
    ownGame(data) {
      return (
        this.mannschaft &&
        this.mannschaft.id &&
        (data.heimmannschaft === this.mannschaft.name ||
          data.gastmannschaft === this.mannschaft.name)
      );
    },
    statusAllowsEditing(data) {
      if (data && data.status) {
        switch (data.status) {
          case "ToPlay":
          case "BeingPlayed":
          case "WaitingForResults":
          case "WaitingForImage":
            return true;
          default:
            return false;
        }
      }
      return false;
    },
    gamePlayed(data) {
      return data.ergebnis && data.ergebnis != " : ";
    },
    rowClass(data) {
      return this.ownGame(data) ? "owngame" : null;
    },
    onPage(event) {
      this.currentIndex = event.page;
      this.currentGameDay = this.gameDays[event.page];
      this.$emit("update:gameDayViewIndex", this.currentIndex);
    },
    translateGameStatus(game) {
      if (game && game.status) {
        switch (game.status) {
          case "ToPlay":
            return "Zu spielen";
          case "BeingPlayed":
            return "Wird gerade gespielt";
          case "WaitingForResults":
            return "Noch kein Endergebnis";
          case "WaitingForImage":
            return "Noch kein Bild des Endergebnisses";
          case "WaitingForHomeApproval":
            return "In Bestätigung durch Heim";
          case "InDiscussion":
            return "In Abstimmung";
          case "WaitingForGuestApproval":
            return "In Bestätigung durch Gast";
          case "WaitingForGameManagerApproval":
            return "In Kontrolle durch Spielleiter";
          case "Played":
            return "Gespielt";
          case "PostPoned":
            return "Verlegt";
          case "Unrated":
            return "Keine Wertung";
          case "ChangeRequested":
            return "Änderung beim Gegner angefragt";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="css">
.owngame {
  background-color: #b9c4ff !important;
}
@media (max-width: 550px) {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 2px 7px !important;
  }
  .p-datatable[pv_id_2] .p-datatable-tbody > tr > td:is(:last-child) {
    justify-content: flex-end !important;
  }
  .gamesView {
    display: block !important;
  }

  /* Darken the line between the entries in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: right !important;
    border: 1px solid #555;
    border-width: 0 0 2px 0;
  }
  /* Enlarge distance between each entry in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td:is(:last-child) {
    margin-bottom: 0.7rem;
    padding-bottom: 1rem;
  }
}
</style>
